import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { css } from "@emotion/core";
import { Link } from "react-router-dom";

export default function FormFinal({
  copied,
  handleCopied,
  fullApology,
  handleFullApologyChange,
}) {
  useEffect(() => {
    document.body.style.transition = "all ease 1000ms";
    // document.body.style.backgroundColor = "#64b5f6";
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const textArea = document.querySelector("textarea");
    textArea.style.height = "inherit";
    textArea.style.height = `${textArea.scrollHeight + 5}px`;
  });

  return (
    <div className="form-step">
      <p>Here is your apology. </p>
      <p className="explanation">
        It’s probably not perfect, but neither is anyone. It’s still worth
        sharing.
      </p>
      <textarea
        className="final"
        value={fullApology}
        onChange={handleFullApologyChange}
      ></textarea>
      {copied ? (
        <button className="center">
          <i className="fas fa-check"></i>
          Copied!
        </button>
      ) : (
        <button
          className="center"
          onClick={() => {
            navigator.clipboard.writeText(fullApology);
            ReactGA.event({
              category: "BUTTON",
              action: "User clicked Copy to Clipboard",
              label: "MAIN_PAGE",
            });
            handleCopied();
          }}
        >
          <i className="far fa-copy"></i>
          Copy
        </button>
      )}

      <div className="final">
        <p>Your Apology is a gift.</p>
        <p className="explanation">
          It is not a chance to defend yourself.
          <br />
          It is not a demand for the receiver to change.
          <br />
          It is not a request for a return apology for the role they played.
        </p>
        <p className="explanation">All those conversations may happen later.</p>
        <p className="explanation">
          It is purely an opportunity for you to give the person you have
          wronged some relief, and to let them know you care about them.
        </p>
      </div>
      <button className="link-button">
        <i className="fas fa-link"></i>
        <a
          target="_blank"
          href="https://www.reddit.com/user/lgomeszanforlin/comments/i8lhn0/i_want_to_say_sorry/?utm_source=share&utm_medium=web2x"
        >
          Apology Forum
        </a>
      </button>
      <div className="footer">
        <p className="footer">
          Made with love by{" "}
          <a target="_blank" href="https://www.makuxr.com/">
            Maku XR
          </a>{" "}
          and{" "}
          <a target="_blank" href="https://github.com/gortron">
            Gordy
          </a>
          , with gratitude to Harriet Lerner and Brené Brown. © Maku XR 2020
        </p>
      </div>
    </div>
  );
}
