import React from "react";

function Intro({ handleStep }) {
  return (
    <div className="form-step">
      <h1>I Want To Say Sorry</h1>
      <p>Everyone messes up sometimes. The important thing is to apologize.</p>
      <p>Saying sorry is hard. We made this to help us remember how.</p>
      <p className="explanation">
        This is just for you. None of your words are sent anywhere, they're just stored on your device.
      </p>
      <button className="start" onClick={() => handleStep("forward")}>
        Let's get started
      </button>
    </div>
  );
}

export default Intro;
