import React, { useState, useEffect } from "react";

/** @jsx jsx */
import { jsx } from "@emotion/core";

import { omit } from "lodash";
import { useTransition, animated } from "react-spring";

import ReactGA from "react-ga";
import ttiPolyfill from "tti-polyfill";

import Head from "./Head";
import Intro from "./Intro";
import ReadyCheck from "./ReadyCheck";
import FormQuestion from "./FormQuestion";
import FormInformationalStep from "./FormInformationalStep";
import FormEdit from "./FormEdit";
import FormFinal from "./FormFinal";
import ResetDialogue from "./ResetDialogue";
import copy from "./utils/content";

import "./App.css";

export default function App() {
  const [step, setStep] = useState(0);
  const [statement, setStatement] = useState({
    name: "",
    context: "",
    actions: "",
    defense: "",
    apology: "",
    change: "",
  });
  const [fullApology, setFullApology] = useState("");
  const [direction, setDirection] = useState("forward");
  const [init, setInit] = useState(true);
  const [copied, setCopied] = useState(false);
  const [hotkey, setHotkey] = useState(false);
  const [resetDialogue, setResetDialogue] = useState(false);

  // GA trackers for page views, time to interactive
  useEffect(() => {
    ReactGA.initialize("UA-173951209-1");
    ttiPolyfill.getFirstConsistentlyInteractive().then((tti) => {
      ReactGA.timing({
        category: "Load Performace",
        variable: "Time to Interactive",
        value: tti,
      });
    });

    const handleKeydown = (e) => {
      if (step === 14 || step === 0) return;
      if (e.which === 40 && hotkey) {
        const textArea = document.querySelector("textarea");
        if (textArea) {
          textArea.style.height = "inherit";
          textArea.style.height = `${5}px`;
        }
        setDirection("forward");
        setStep(step + 1);
        const state = {
          statement,
          step: step + 1,
          fullApology,
        };
        localStorage.setItem("state", JSON.stringify(state));
      }
      if (e.which === 38 && hotkey) {
        const textArea = document.querySelector("textarea");
        if (textArea) {
          textArea.style.height = "inherit";
          textArea.style.height = `${5}px`;
        }
        setDirection("backward");
        setStep(step - 1);
        const state = { statement, step: step - 1, fullApology };
        localStorage.setItem("state", JSON.stringify(state));
      }
      if (e.key === "Shift") {
        setHotkey(true);
      }
    };

    const handleKeyup = (e) => {
      if (e.key === "Shift") {
        setHotkey(false);
      }
    };

    // keylisteners
    document.addEventListener("keydown", handleKeydown, false);
    document.addEventListener("keyup", handleKeyup, false);

    return () => {
      document.removeEventListener("keydown", handleKeydown, false);
      document.removeEventListener("keyup", handleKeyup, false);
    };
  }, [fullApology, hotkey, statement, step]);

  useEffect(() => {
    // localStorage.clear();

    const local = JSON.parse(localStorage.getItem("state"));
    if (local && local.step !== 1) setResetDialogue(true);
    if (local && local.statement) {
      setStatement(local.statement);
    }
    if (local && local.fullApology) {
      setFullApology(local.fullApology);
    }
    // this is where we want to handle user reset
    // this breaks when user goes step 0 to step 1 and init changes...
    if (local && local.step) {
      if (init) setInit(false);
      setStep(local.step);
    }
  }, [init]);

  const handleStep = (direction) => {
    const textArea = document.querySelector("textarea");
    if (textArea) {
      textArea.style.height = "inherit";
      textArea.style.height = `${5}px`;
    }
    if (init) setInit(false);
    if (direction === "forward") {
      setStep(step + 1);
      setDirection(direction);
      updateLocalStorage(1);
    } else {
      setStep(step - 1);
      setDirection(direction);
      updateLocalStorage(-1);
    }
  };

  const updateLocalStorage = (calibration) => {
    const state = {
      statement,
      step: step + calibration,
      fullApology,
    };
    console.log("updating local", JSON.stringify(state));
    localStorage.setItem("state", JSON.stringify(state));
  };

  // adjust styling and reset local storage
  const handleCopied = () => {
    setCopied(true);
    localStorage.clear();
  };

  const filterAndStringifyObj = (obj = statement, filterKeys = ["defense"]) => {
    let filteredObj = omit(obj, filterKeys);
    filteredObj.name = filteredObj.name + ",";
    let stringedObj = Object.values(filteredObj).join("\n\n");
    return stringedObj;
  };

  const handleFullApologyChange = (e) => {
    const target = e.target;
    console.log(target.value);
    setFullApology(target.value);
  };

  const handleStatementChange = (e) => {
    const target = e.target;
    let updatedStatement;
    switch (target.name) {
      case "name":
        let name = target.value;
        updatedStatement = { ...statement, name };
        setStatement(updatedStatement);
        setFullApology(filterAndStringifyObj(updatedStatement, "defense"));
        break;
      case "context":
        let context = target.value;
        updatedStatement = { ...statement, context };
        setStatement(updatedStatement);
        setFullApology(filterAndStringifyObj(updatedStatement, "defense"));
        break;
      case "actions":
        let actions = target.value;
        updatedStatement = { ...statement, actions };
        setStatement(updatedStatement);
        setFullApology(filterAndStringifyObj(updatedStatement, "defense"));
        break;
      case "defense":
        let defense = target.value;
        updatedStatement = { ...statement, defense };
        setStatement(updatedStatement);
        setFullApology(filterAndStringifyObj(updatedStatement, "defense"));
        break;
      case "apology":
        let apology = target.value;
        updatedStatement = { ...statement, apology };
        setStatement(updatedStatement);
        setFullApology(filterAndStringifyObj(updatedStatement, "defense"));
        break;
      case "change":
        let change = target.value;
        updatedStatement = { ...statement, change };
        setStatement(updatedStatement);
        setFullApology(filterAndStringifyObj(updatedStatement, "defense"));
        break;
      default:
        console.error("Invalid apology field.");
    }
    // let updateName = target.name;
    // let updateValue = target.value;
    // let currentStatement = { ...statement, updateName: updateValue };
    // let stringedStatement = filterAndStringifyObj(currentStatement, "defense");
    // setFullApology(stringedStatement);
  };

  const pages = [
    // 0
    (item, style, key) => (
      <animated.div key={key} style={{ ...style }}>
        <Intro handleStep={handleStep} />
      </animated.div>
    ),
    // 1
    (item, style, key) => (
      <animated.div key={key} style={{ ...style }}>
        <ReadyCheck handleStep={handleStep} />
      </animated.div>
    ),
    // 2
    (item, style, key) => (
      <animated.div key={key} style={{ ...style }}>
        <FormQuestion
          content={copy[item]}
          handleStep={handleStep}
          statement={statement}
          handleStatementChange={handleStatementChange}
        />
      </animated.div>
    ),
    // 3
    (item, style, key) => (
      <animated.div key={key} style={{ ...style }}>
        <FormQuestion
          content={copy[item]}
          step={step}
          handleStep={handleStep}
          statement={statement}
          handleStatementChange={handleStatementChange}
        />
      </animated.div>
    ),
    // 4
    (item, style, key) => (
      <animated.div key={key} style={{ ...style }}>
        <FormQuestion
          content={copy[item]}
          step={step}
          handleStep={handleStep}
          statement={statement}
          handleStatementChange={handleStatementChange}
        />
      </animated.div>
    ),
    // 5
    (item, style, key) => (
      <animated.div key={key} style={{ ...style }}>
        <FormQuestion
          content={copy[item]}
          handleStep={handleStep}
          statement={statement}
          handleStatementChange={handleStatementChange}
        />
      </animated.div>
    ),
    // 6
    (item, style, key) => (
      <animated.div key={key} style={{ ...style }}>
        <FormQuestion
          content={copy[item]}
          handleStep={handleStep}
          statement={statement}
          handleStatementChange={handleStatementChange}
        />
      </animated.div>
    ),
    // 7
    (item, style, key) => (
      <animated.div key={key} style={{ ...style }}>
        <FormQuestion
          content={copy[item]}
          handleStep={handleStep}
          statement={statement}
          handleStatementChange={handleStatementChange}
        />
      </animated.div>
    ),
    // 8
    (item, style, key) => (
      <animated.div key={key} style={{ ...style }}>
        <FormInformationalStep
          step={step}
          prompt={"Now let's edit your apology."}
          explanation={
            "Words that might spoil your apology will be highlighted. But language is complex, and words can be used in many ways. Ultimately, you must decide for yourself if your words are valid or not. Click on the text box to show your apology."
          }
          handleStep={handleStep}
        />
      </animated.div>
    ),
    // 9
    (item, style, key) => (
      <animated.div key={key} style={{ ...style }}>
        <FormEdit
          content={copy[item]}
          step={step}
          handleStep={handleStep}
          fullApology={fullApology}
          handleFullApologyChange={handleFullApologyChange}
        />
      </animated.div>
    ),
    // 10
    (item, style, key) => (
      <animated.div key={key} style={{ ...style }}>
        <FormEdit
          content={copy[item]}
          step={step}
          handleStep={handleStep}
          fullApology={fullApology}
          handleFullApologyChange={handleFullApologyChange}
        />
      </animated.div>
    ),
    // 11
    (item, style, key) => (
      <animated.div key={key} style={{ ...style }}>
        <FormEdit
          content={copy[item]}
          step={step}
          handleStep={handleStep}
          fullApology={fullApology}
          handleFullApologyChange={handleFullApologyChange}
        />
      </animated.div>
    ),
    // 12
    (item, style, key) => (
      <animated.div key={key} style={{ ...style }}>
        <FormEdit
          content={copy[item]}
          step={step}
          handleStep={handleStep}
          fullApology={fullApology}
          handleFullApologyChange={handleFullApologyChange}
        />
      </animated.div>
    ),
    // 13
    (item, style, key) => (
      <animated.div key={key} style={{ ...style }}>
        <FormEdit
          content={copy[item]}
          handleStep={handleStep}
          fullApology={fullApology}
          handleFullApologyChange={handleFullApologyChange}
        />
      </animated.div>
    ),
    // 14
    (item, style, key) => (
      <animated.div key={key} style={{ ...style }}>
        <FormFinal
          copied={copied}
          handleCopied={handleCopied}
          fullApology={fullApology}
          handleFullApologyChange={handleFullApologyChange}
        />
      </animated.div>
    ),
  ];

  const transitions = useTransition(step, (p) => p, {
    config: {
      mass: 1,
      tension: 100,
      friction: 20,
      clamp: true,
    },
    from: {
      width: "100%",
      position: "absolute",
      transform: `translate3d(0,${
        direction === "forward" ? "100vh" : "-100vh"
      },0)`,
    },
    enter: {
      width: "100%",
      position: "absolute",
      opacity: 1,
      transform: "translate3d(0%,0,0)",
    },
    leave: {
      width: "100%",
      position: "absolute",
      transform: `translate3d(0,${
        direction === "forward" ? "-100vh" : "100vh"
      },0)`,
    },
  });

  const handleContinue = () => {
    setResetDialogue(false);
  };

  const handleReset = () => {
    localStorage.clear();
    setInit(true);
    setStep(0);
    setResetDialogue(false);
    setStatement({
      name: "",
      context: "",
      actions: "",
      defense: "",
      apology: "",
      change: "",
    });
  };

  const handleDisplayLogic = () => {
    if (resetDialogue) {
      return (
        <ResetDialogue
          handleReset={handleReset}
          handleContinue={handleContinue}
          statement={statement}
        />
      );
    } else if (init) {
      return <Intro handleStep={handleStep} />;
    } else {
      return transitions.map(({ item, props, key }) => {
        return pages[item](item, props, key);
      });
    }
  };

  return (
    <div className="App">
      <Head />
      {handleDisplayLogic()}
    </div>
  );
}

// {init ? (
//   <Intro handleStep={handleStep} />
// ) : (
//   transitions.map(({ item, props, key }) => {
//     return pages[item](item, props, key);
//   })
// )}
