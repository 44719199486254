import React from "react";
import { Helmet } from "react-helmet";
import createdTypography from "./utils/typography";

function Head() {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Sorry</title>
      <link rel="canonical" href="https://www.iwanttosaysorry.com/" />
      {/* <link
        href="https://fonts.googleapis.com/css?family=Comfortaa|Inconsolata"
        rel="stylesheet"
        type="text/css"
      />
      <script
        src="https://kit.fontawesome.com/7b4f1a0571.js"
        crossorigin="anonymous"
      ></script> */}

      <style>{createdTypography}</style>
    </Helmet>
  );
}

export default Head;
