import React, { useEffect, useState } from "react";
import FormStepButtons from "./FormStepButtons";

import { HighlightWithinTextarea } from "react-highlight-within-textarea";

export default function FormEdit({
  content,
  step,
  handleStep,
  fullApology,
  handleFullApologyChange,
}) {
  const hasKeywords = (message, keywords) => {
    // given key for list of keywords, return t/f
    const pass =
      message.length > 0 &&
      keywords.some((keyword) => {
        let scrubbedMessage = message.toLowerCase().replace(/'/g, "");
        let scrubbedKeyword = keyword.toLowerCase().replace(/'/g, "");
        return scrubbedMessage.includes(scrubbedKeyword);
      });
    return pass;
  };

  const { prompt, explanation, message, keywords } = content;

  const handleResize = (e) => {
    e.target.style.height = "inherit";
    e.target.style.height = `${e.target.scrollHeight + 5}px`;
  };

  return (
    <div className="form-step">
      <p>{prompt}</p>
      <p className="explanation">{explanation}</p>
      <HighlightWithinTextarea
        highlight={keywords}
        onChange={handleFullApologyChange}
        containerClassName="highlight-container"
        value={fullApology}
        onKeyDown={handleResize}
        onFocus={handleResize}
        rows={1}
      />
      <p className="success">
        {!hasKeywords(fullApology, keywords) && message}
      </p>
      <FormStepButtons handleStep={handleStep} />
    </div>
  );
}
