import Typography from "typography";

const typography = new Typography({
  baseFontSize: "18px",
  baseLineHeight: 1.5,
  headerFontFamily: ["Comfortaa", "serif"],
  bodyFontFamily: ["Inconsolata", "serif"],
});

const createdTypography = typography.createStyles();
// console.log(createdTypography);

export default createdTypography;
