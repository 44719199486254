import React, { useState } from "react";
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import FormStepButtons from "./FormStepButtons";

function ReadyCheck({ handleStep }) {
  const [readyMessage, setReadyMessage] = useState(null);
  const [checkedNotReally, setCheckedNotReally] = useState(false);
  const [checkedPartly, setCheckedPartly] = useState(false);
  const [checkedActuallySorry, setCheckedActuallySorry] = useState(false);

  const handleReadinessCheckbox = (e) => {
    setCheckedNotReally(false);
    setCheckedPartly(false);
    setCheckedActuallySorry(false);
    const target = e.target;
    let message;
    switch (target.name) {
      case "notReally":
        setCheckedNotReally(true);
        message =
          "Thanks for your honesty! Come back whenever you’re ready.        ";
        break;
      case "partlyMyFault":
        setCheckedPartly(true);
        message =
          "Being the first to apologize takes courage and humility. Make sure you feel no resentment before you begin.";
        break;
      case "imActuallySorry":
        setCheckedActuallySorry(true);
        message = "Then let’s write this apology!";
        break;
      default:
        console.error("Invalid checkfield was checked.");
    }
    setReadyMessage({ message });
  };

  return (
    <div className="form-step">
      <p>First: do you truly believe you did something wrong?</p>
      <label>
        <input
          name="notReally"
          type="checkbox"
          checked={checkedNotReally}
          onChange={handleReadinessCheckbox}
        />{" "}
        Not Really
      </label>
      <br />
      <label>
        <input
          name="partlyMyFault"
          type="checkbox"
          checked={checkedPartly}
          onChange={handleReadinessCheckbox}
        />{" "}
        Partly My Fault
      </label>
      <br />
      <label>
        <input
          name="imActuallySorry"
          type="checkbox"
          checked={checkedActuallySorry}
          onChange={handleReadinessCheckbox}
        />{" "}
        I'm Actually Sorry
      </label>
      {readyMessage && (
        <p
          css={css`
            margin-top: 1.5rem;
            margin-bottom: 0rem !important;
          `}
          className="success"
        >
          {readyMessage.message}
        </p>
      )}
      {checkedActuallySorry ? (
        <FormStepButtons handleStep={handleStep} />
      ) : (
        <div
          css={css`
            pointer-events: none;
            opacity: 0.5;
          `}
        >
          <FormStepButtons handleStep={handleStep} hideInstruction={true} />
        </div>
      )}
    </div>
  );
}

export default ReadyCheck;
