import React from "react";
import FormStepButtons from "./FormStepButtons";

export default function FormInformationalStep({
  prompt,
  explanation,
  handleStep,
}) {
  return (
    <div className="form-step">
      <p>{prompt}</p>
      <p className="explanation">{explanation}</p>
      <FormStepButtons handleStep={handleStep} />
    </div>
  );
}
