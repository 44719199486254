const content = {
  2: {
    name: "name",
    prompt: "Who would you like to say sorry to?",
    explanation: "Just their first name is fine.",
    placeholder: "Type their name here...",
    type: "text",
  },
  3: {
    name: "context",
    prompt:
      "Explain to them what led up to the event that you're apologizing for?",
    explanation:
      "Describe the context, not what you did wrong. Don't use third person.",
    placeholder: "You've been doing...",
    type: "textarea",
  },
  4: {
    name: "actions",
    prompt: "What did you do wrong?",
    explanation: "Describe your actions using “I” statements.",
    placeholder: "Yesterday, I did...",
    type: "textarea",
  },
  5: {
    name: "defense",
    prompt: "If you need to defend yourself, do it here.",
    explanation: "This won't be included in your apology.",
    placeholder: "Honestly, I think...",
    type: "textarea",
  },
  6: {
    name: "apology",
    prompt: "Apologize to them.",
    explanation: `Restate the main action you did. Use the words “Sorry,” or “I
    apologize.”`,
    placeholder: "I'm sorry that...",
    type: "textarea",
  },
  7: {
    name: "change",
    prompt: "How will you change in the future?",
    explanation: "Only offer something you genuinely will do.",
    placeholder: "In the future I...",
    type: "textarea",
    postscript: "If you leave this blank, you're not actually apologizing.",
  },
  9: {
    prompt: "Take out all excuses for your behavior. ",
    explanation: `Excuses often appear with "but" or "I didn't know/mean/intend.” Click on the name below to show your apology.`,
    message:
      "There don't seem to be any excuses for your behavior, but you should check for yourself.",
    keywords: [
      " but ",
      ". But ",
      "I didn't know ",
      "I didn't mean ",
      "I didn't intend ",
      "I thought ",
      " my intention ",
      "My intention ",
      " my objective ",
      " My objective ",
      "I've been ",
      " because ",
      " Because ",      
    ],
  },
  // removed "the situation" keyword
  10: {
    prompt: "Change all third person (it) into the first person (I).",
    explanation: "This helps you take responsibility for what you did. Click on the name below to show your apology.",
    message:
      "Can't find any third person language, but you should check for yourself too.",
    keywords: [
      "The situation", 
      " the situation", 
      " it ", 
      ". It ",
      " it.",
    ],
  },
  11: {
    prompt: "Remove your own emotions",
    explanation: "They may be honest, but they can burden the receiver. Click on the name below to show your apology.",
    message:
      "No emotional language was found but you should double-check, these statements can be sneaky.",
    keywords: [
      "I feel ",
      "I'm sad ",
      "I was broken ",
      "I'm broken ",
      "I'm destroyed ",
      "I'm distraught ",
      "I'm crying ",
      "I cried ",
      "Guilty ",
      " guilty ",
      "Shameful  ",
      " shameful  ",
      " Ashamed ",
      " ashamed ",
    ],
  },
  12: {
    prompt: "Remove assumptions",
    explanation: "These often appear around the word “if” or “I understand.” Click on the name below to show your apology.",
    message:
      "Can't find any assumptions, but you should check for yourself too. You're almost done.",
    keywords: [
      " if ",
      ". If ",
      "I understand ",
      "You must be ",
       "you must be ",
      "you must feel ",
      "You must feel ",
      " you have to feel ",
      "You have to feel ",
      " you have to be ",
      "You have to be ",
      " you probably ",
      "You probably ",
      " I think you ",
      " you might be ",
      "You might be ",
      " you may be ",
      "You may be ",
      "You are probably ",
      "You are probably",
    ],
  },
  13: {
    prompt: "Remove asks",
    explanation: `“Forgive me” or “please” ask something of the receiver. Click on the name to show your apology.`,
    message: "No asks were found. This is the last step!",
    keywords: [
      "Forgive me",
      "forgive me",
      "Please",
      "please",
      "I want you to",
      "Understand",
      "understand",
      "?",
      "You have to",
      " you have to",
      "You must",
      " you must",
      "You should",
      " you should",
    ],
  },
};

export default content;
