import React from "react";
import FormStepButtons from "./FormStepButtons";

export default function FormQuestion({
  content,
  handleStep,
  statement,
  handleStatementChange,
}) {
  const { name, prompt, explanation, placeholder, type, postscript } = content;

  const handleResize = (e) => {
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight + 2}px`;
  };

  const renderInput = () => {
    if (type === "text") {
      return (
        <input
          className="text"
          type={type}
          placeholder={placeholder}
          name={name}
          onChange={handleStatementChange}
          value={statement[name]}
        />
      );
    } else if (type === "textarea") {
      return (
        <textarea
          rows={1}
          placeholder={placeholder}
          name={name}
          onChange={handleStatementChange}
          value={statement[name]}
          onKeyDown={handleResize}
          onFocus={handleResize}
        />
      );
    } else if (type === "none") {
      return;
    } else {
      console.error(`Could not recognize input type of ${type}`);
    }
  };

  return (
    <div className="form-step">
      <p>{prompt}</p>
      <p className="explanation">{explanation}</p>
      {renderInput()}
      {postscript && <p className="explanation">{postscript}</p>}
      <FormStepButtons handleStep={handleStep} />
    </div>
  );
}
