import React from "react";

export default function ResetDialogue({
  handleReset,
  handleContinue,
  statement,
}) {
  return (
    <div className="form-step">
      <p className="explanation">
        Looks like you have an apology {statement.name.length > 0 && "for "}
        <b>{statement.name.length > 0 && statement.name + " "}</b>
        you already started.
      </p>
      <p>
        Would you like to write a <b>new</b> apology, or <b>continue</b> the old
        one?
      </p>
      <div className="reset-buttons">
        <button className="reset-button" onClick={handleReset}>
          <i class="fas fa-plus"></i>
          New
        </button>
        <button className="reset-button" onClick={handleContinue}>
          <i class="far fa-edit"></i>
          Continue
        </button>
      </div>
    </div>
  );
}
