import React from "react";

export default function FormStepButtons({
  handleStep,
  hideInstruction = false,
}) {
  return (
    <div className="step-buttons-container">
      <button className="return" onClick={() => handleStep("forward")}>
        <i className="fas fa-check"></i>
        OK
      </button>
      {!hideInstruction && (
        <>
          <p className="icon">
            press <b>Shift</b> + <b>Down</b>
          </p>
          <i className="fas fa-level-down-alt"></i>
        </>
      )}
      <div className="step-buttons">
        <button className="step-button" onClick={() => handleStep("forward")}>
          <i className="fas fa-chevron-down"></i>
        </button>
        <button className="step-button" onClick={() => handleStep("backward")}>
          <i className="fas fa-chevron-up"></i>
        </button>
      </div>
    </div>
  );
}
